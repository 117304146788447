:root {
  --bg: #f5f7fb;
  --border-bottom: #dbdef0;
  --selected-button: #d6dbf5;
  --button-border: #4d5b9e;
  --button-text-color: #293264;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--bg);
  font-family: sans-serif;
  overflow-x: hidden;
}

.upper-design {
  width: 400px;
  height: 300px;
  position: absolute;
  right: -3%;
  top: -20%;
  background: #fffad1;
  border-radius: 69% 31% 48% 52% / 0% 75% 25% 100%;
  z-index: 0;
}

.lower-design {
  width: 350px;
  height: 350px;
  position: absolute;
  left: -10%;
  bottom: -25%;
  background: #deebf8;
  border-radius: 69% 31% 47% 53% / 50% 32% 68% 50%;
  z-index: 0;

}

/* ----------Initial Page Container---------- */
div.init-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg);
}



div.init-page-container h1 {
  color: var(--button-text-color);
  margin: 0;
  font-size: 40px;
}

div.init-page-container p {
  color: var(--button-text-color);
  margin-top: 25px;
  font-size: 20px;
  font-weight: 100;
}

div.init-page-container button.start-button {
  font-size: 30px;
  padding: 15px 60px;
  font-size: 20px;
  margin-top: 10px;
}

/* ----------Data Container---------- */

div.data-container {
  width: 70vw;
  height: 80vh;
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

/* ----------Buttons---------- */

.button {
  background-color: var(--button-border);
  color: white;
  border: none;
  border-radius: 12px;
}

/* Check Answer Button */

button.check-button {
  margin-top: 30px;
  padding: 11px 30px;
  font-size: 15px;
}

/* Play gain Button */

button.play-again-button {
  margin-top: 10px;
  padding: 11px 30px;
  font-size: 15px;
}

/* ----------Score Section---------- */

div.score {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Score text */
p.score-text {
  margin-top: 20px;
}

/* ----------Questions Component---------- */

div.question {
  border-bottom: 1px solid var(--border-bottom);
  padding: 10px 0 13px 0;
}

div.answers {
  display: flex;
  gap: 20px;
}

p.question-text {
  color: var(--button-text-color);
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
}

/* ----------Answers Component---------- */

button.answer {
  border: 1px solid var(--button-border);
  border-radius: 12px;
  padding: 7px 15px;
  color: var(--button-text-color);
  font-size: 12px;
  background-color: #ffffff00;
}